import React from 'react'
import Layout from '../components/Layout'
import { makeStyles } from '@material-ui/core/styles';
import SEO from '../components/SEO'

const useStyles = makeStyles(theme => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    height: 'calc(100vh - 2 * 70px)',
    margin: '0 auto',
    backgroundColor: 'white',
    [theme.breakpoints.up('sm')]: {
      height: 'calc(100vh - 2 * 130px)',
    }
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
    position: 'relative',
    backgroundColor: 'transparent',
    zIndex: 2,
    margin: 0,
  },
}));

function OfferHelp() {
  const classes = useStyles();
  return (
    <Layout>
      <SEO
        title="Hilfe bieten"
        description={`Trage Dich ein bei "Emden hilft" und engagiere dich für deine Mitbürger in Emden. Jede helfende Hand zählt.`}
      />
      <div className={classes.wrapper}>
        <iframe
          className={classes.iframe}
          title="Emden hilft - Hilfe anbieten Formular"
          src="https://docs.google.com/forms/d/e/1FAIpQLSdUOcsN3lo9zpYmivxZwxAmXNuXMKEtpQdkwRVDysWwX6ttTQ/viewform?embedded=true"
        >
          Wird geladen…
        </iframe>
      </div>
    </Layout>
  )
}

export default OfferHelp
